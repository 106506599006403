import { hasRoute } from '@common/utils'
import { FastlyImage } from '@components/common/image'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import ReactModal from 'react-modal'
import { parseCookies, setCookie } from 'nookies'

// popupName is used to store the popup name in the session storage, so we can use this component to show different popups on different pages
// restrict the popup to number of times in a day
type PopupProps = {
  displayPopup: boolean
  imageUrl: string
  targetUrl: string
  imageWidth: number
  imageHeight: number
  popupName: string
  numberOfTimestoShow?: number // in a week
  onceInDays?: number // Once in how many days the popup should be shown
  useWebp?: boolean
}

// Paths that should not show the hello bar
const RESTRICTED_PATHS = [
  '/batches/**',
  '/lp/**',
  '/trial-class/**',
  '/competitions/**/problems/**',
  '/competitions/**/problems',
  '/competitions/**/competition-leaderboard',
  '/competitions/**/submissions',
  '/competitions/**',
  '/book-trial-class',
  '/payment/**',
  '/rewind/**',
  '/payment-status*',
  '/register*',
  '/login*',
  '/register/**',
  '/login/**',
  '/404',
]

// Temporary popup Component can be used to show popup on any page.
// Todo: Make it as a generic component, so that it can be configured from the _app.tsx
// Add allowed routerpath, country, name of the popup,.. as Props to show popup based on the configuration
const Popup = ({
  displayPopup,
  imageUrl,
  targetUrl,
  imageWidth,
  imageHeight,
  popupName,
  numberOfTimestoShow = 2, // in a week
  onceInDays = 7, // Default to 7 days
  useWebp = true,
}: PopupProps) => {
  const { pathname } = useRouter()
  const [showPopup, setShowPopup] = useState(false)
  const [isAllowedPath, setIsAllowedPath] = useState(false)

  useEffect(() => {
    const cookies = parseCookies()
    const popupCookie = cookies[`offerPopup_${popupName}`]
    const currentDate = new Date().toDateString()
    let popupData = popupCookie
      ? JSON.parse(popupCookie)
      : { date: '', count: 0 }

    // Reset if the date is different
    if (popupData.date !== currentDate) {
      popupData = { date: currentDate, count: 0 }
    }

    // Check session storage (only once per session)
    const sessionKey = `offerPopupSession_${popupName}`
    const hasShownInSession = sessionStorage.getItem(sessionKey)

    // If on a restricted path, do not update state
    if (hasRoute(pathname, RESTRICTED_PATHS)) {
      setIsAllowedPath(false)
      return
    } else {
      setIsAllowedPath(true)
    }

    // Delay the popup appearance by 5 seconds when navigating to an allowed path
    const timer = setTimeout(() => {
      // If already shown max times or in session, prevent showing
      if (!hasShownInSession && popupData.count < numberOfTimestoShow) {
        setShowPopup(displayPopup)
      } else {
        setShowPopup(false)
      }
    }, 5000)

    return () => clearTimeout(timer)
  }, [displayPopup, popupName, pathname]) // Added `pathname` dependency to handle route changes

  useEffect(() => {
    if (showPopup) {
      const cookies = parseCookies()
      const popupCookie = cookies[`offerPopup_${popupName}`]
      const popupData = popupCookie
        ? JSON.parse(popupCookie)
        : { date: new Date().toDateString(), count: 0 }

      popupData.count += 1

      // Save to sessionStorage to prevent multiple popups in the same session
      const sessionKey = `offerPopupSession_${popupName}`
      sessionStorage.setItem(sessionKey, 'true')

      // Set the cookie to expire in 1 day
      setCookie(null, `offerPopup_${popupName}`, JSON.stringify(popupData), {
        maxAge: 24 * 60 * 60 * onceInDays, // X days in seconds
        path: '/',
      })
    }
  }, [showPopup, popupName])

  // Override popup visibility based on restricted paths
  if (!isAllowedPath) return null

  if (showPopup)
    return (
      <ReactModal
        className="fixed inset-0 inset-x-0 flex items-center justify-center px-4 pb-4 outline-none"
        overlayClassName="z-50 fixed inset-0 bg-smoke"
        isOpen={showPopup}
        shouldCloseOnEsc={true}
        onRequestClose={() => setShowPopup(false)}
      >
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => setShowPopup(false)}
        >
          <div className="absolute inset-0 opacity-7 bg-grey bg-opacity-50" />
        </div>
        <div
          className="relative z-30 content-center"
          style={{ height: '80%' }}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-end">
            <button
              className="focus:outline-none bg-white hover:bg-grey-600 bg-opacity-25"
              onClick={() => setShowPopup(false)}
            >
              <X className="stroke-2 text-orange" />
            </button>
          </div>
          <a
            href={targetUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => setShowPopup(false)}
          >
            <FastlyImage
              src={`${imageUrl}.png`}
              webpSrc={useWebp ? `${imageUrl}.webp` : undefined}
              alt="Codingal blackfriday popup"
              className="w-auto"
              width={imageWidth}
              height={imageHeight}
            />
          </a>
        </div>
      </ReactModal>
    )
  return null
}
export default Popup
