import 'core-js/features/object/from-entries'
import '../styles/common.sass'
import '../styles/phone-input.sass'
import '../styles/hbspt-form.css'

import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { parseCookies } from 'nookies'
import Head from 'next/head'
import Script from 'next/script'
import Router, { useRouter } from 'next/router'
import { DefaultSeo, NextSeo } from 'next-seo'
import minimatch from 'minimatch'
import getConfig from 'next/config'
import { FlagsProvider } from 'flagged'
import { LOGO_THEME } from '@lib/constants/common'
import { staticFile, hasRoute } from '@common/utils'
import { SessionContext } from '@contexts/session'
import { userFetcher } from 'data-fetchers/common/user-fetcher'
import {
  UNAUTHORIZED_ONLY_ENDPOINTS,
  AUTHORIZED_ONLY_ENDPOINTS,
  UPDATE_INFO_HELLO_BAR_ENDPOINTS,
  LEADSQUARED_EXCLUSION_GLOBS,
  GENERAL_HELLO_BAR_EXCLUSION_GLOBS,
} from 'common/constants'

import {
  generateUTMParametersDict,
  storeLastTouchUTMParametersInSessionCookie,
} from '@lib/analytics/track-utm'
import {
  LOCAL_STORAGE_UTM_PARAMS_KEY,
  SIGNUP_REFERRER_LOCAL_STORAGE_KEY,
} from '@lib/constants/tracking'
import { setToLocalStorage } from '@lib/utils/local-storage'

import { getFeaturesForUser } from '@lib/utils/feature-flag'
import { AW_CONVERSION_ID, GA_TRACKING_ID, pageview } from '@lib/analytics/gtag'
import dynamic from 'next/dynamic'
import {
  redirectToForGetInitialProps,
  removeLocaleFromPathname,
} from '@lib/utils/common'
import { i18n } from '@lingui/core'
import {
  getLangLocaleFromLocale,
  handleLocaleRedirections,
  initTranslation,
  loadTranslation,
} from '@lib/utils/i18n'
import { I18nProvider } from '@lingui/react'
import { LOCALE_WISE_CANONICAL_LOCALIZED_PATH_GLOBS } from '@lib/constants/i18n'
import { defaultSeo, NOINDEX_PAGE_GLOBS } from '@lib/lp-seo'
import RoutingSpinner from '@components/common/routing-spinner'
import VerifyLocale from '@components/common/i18n/verify-locale'
import CompetitionHelloBar from '@components/hello-bar/competition-hello-bar'
import PasswordResetHelloBar from '@components/hello-bar/password-reset'
import useDetectCountry from '@hooks/useDetectCountry'
import SHA256 from 'crypto-js/sha256'
import { enc } from 'crypto-js'
import AtlasWidget from '@components/common/atlas-widget'
import Popup from '@components/popup/popup'
import HelloBar from '@components/hello-bar/hello-bar'

const UpdateInfoHelloBar = dynamic(() =>
  import('@components/hello-bar/update-info-hello-bar')
)
const {
  publicRuntimeConfig: { PUBLIC_SENTRY_DSN },
} = getConfig()
const isProd = process.env.NODE_ENV === 'production'

if (PUBLIC_SENTRY_DSN) {
  import('@sentry/node').then((Sentry) =>
    Sentry.init({
      enabled: isProd,
      dsn: PUBLIC_SENTRY_DSN,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'TypeError: Failed to fetch',
        'TypeError: e.getAttribute is not a function',
        'TypeError: NetworkError when attempting to fetch resource.',
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'ReferenceError: privateSpecialRepair is not defined',
        'Error: Cannot set headers after they are sent to the client',
        // Errors caused by highlight.run
        'SecurityError: Blocked a frame with origin "https://www.codingal.com" from accessing a cross-origin frame.',
        "TypeError: Cannot read properties of undefined (reading 'prototype')",
        'TypeError: t.report is not a function',
      ],
    })
  )
}

initTranslation(i18n)

declare global {
  interface Window {
    Offline: any
  }
  function pidTracker(id: string): void
}

const NON_CRITICAL_CSS_HREFS = [
  staticFile('/offline/offline-theme.css'),
  staticFile('/offline/offline-language.css'),
]

type Props = {
  Component: any
  pageProps: any
  err: any
  userData: any
  pathname: string
}

export default function App({
  Component,
  pageProps,
  err,
  userData,
  pathname,
}: Props) {
  const {
    id,
    name,
    email,
    loggedIn,
    teacher,
    phone,
    dob,
    gender,
    city,
    zipcode,
    student,
  } = userData
  const router = useRouter()
  const locale = router.locale || router.defaultLocale
  const firstRender = useRef(true)
  const { countryCode } = useDetectCountry({
    isLowerCase: true,
  })

  // Code to determine if the user is eligible for HPE CodeWars 2025 and show the popup and hello bar
  const [showHpeAds, setShowHpeAds] = useState(false)
  const HPE_ALLOWED_GRADE = [
    'Grade 7',
    'Grade 8',
    'Grade 9',
    'Grade 10',
    'Grade 11',
    'Grade 12',
  ]
  useEffect(() => {
    if (!loggedIn) {
      setShowHpeAds(true)
    }
    if (loggedIn && !teacher) {
      if (HPE_ALLOWED_GRADE.includes(student?.grade)) {
        setShowHpeAds(true)
      }
    }
  }, [loggedIn, teacher, student?.grade])

  useEffect(() => {
    if (isProd && loggedIn && PUBLIC_SENTRY_DSN) {
      import('@sentry/node')
        .then((Sentry) => {
          Sentry.setUser({ email: email })
        })
        .catch((error) => {
          console.error('Failed to set Sentry user:', error)
        })
    }
  }, [email, isProd, loggedIn])

  const prepareUserDataForFB = () => {
    let firstName = ''
    let lastName = ''
    if (name && name !== 'Student') {
      const nameParts = name.split(' ') // Split the string into an array
      if (nameParts.length > 1) {
        lastName = nameParts.pop() || '' // Last element as last name
        firstName = nameParts.join(' ') // Remaining elements as first name
      } else {
        firstName = name // If there's only one part, assume it's the first name
      }
    }
    // Retrieve fbc and fbp values from cookies
    const cookies = parseCookies()
    const fbc = cookies._fbc
    const fbp = cookies._fbp

    const hashSha256 = (value) => {
      return value ? SHA256(value).toString(enc.Hex) : null
    }

    // Add user data that are to be hashed before sending to FB
    const userDataforFBEvent = {
      em: email === `${phone?.substr(1)}@codingal-student.com` ? null : email,
      ph: parseInt(phone.replace(/\D/g, ''), 10),
      fn: firstName,
      ln: lastName,
      ge: gender == null ? null : gender === 'Male' ? 'm' : 'f',
      db: dob ? parseInt(dob.replace(/-/g, ''), 10) : null,
      country: countryCode,
      ct: city,
      zp: zipcode,
      external_id: id,
    }

    // Hashing the data for privacy
    // Delete the key if the value is null or undefined
    for (const key in userDataforFBEvent) {
      // Corrected to let
      if (!userDataforFBEvent[key]) {
        delete userDataforFBEvent[key]
      } else {
        userDataforFBEvent[key] = hashSha256(userDataforFBEvent[key])
      }
    }

    // Add fbc and fbp without hashing if they exist in cookies
    if (fbc) {
      userDataforFBEvent['fbc'] = fbc
    }
    if (fbp) {
      userDataforFBEvent['fbp'] = fbp
    }

    return userDataforFBEvent
  }

  if (pageProps.translation && firstRender.current) {
    const langLocale = getLangLocaleFromLocale(locale)
    // Load the translations for the locale
    i18n.load(langLocale, pageProps.translation)
    i18n.activate(langLocale)
    // Render only once
    firstRender.current = false
  }

  // listen for the locale changes
  useEffect(() => {
    if (pageProps.translation) {
      const langLocale = getLangLocaleFromLocale(locale)
      i18n.load(langLocale, pageProps.translation)
      i18n.activate(langLocale)
    }
  }, [locale, pageProps.translation])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (isProd) {
        pageview(url)
      }
    }

    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => Router.events.off('routeChangeComplete', handleRouteChange)
  }, [])

  useEffect(() => {
    if (!loggedIn || !isProd) return

    // Identifying a user when logged in
    window?.globalThis?.analytics?.identify(id, {
      name: name,
      email: email,
    })
  }, [loggedIn])

  useEffect(() => {
    window?.globalThis?.analytics?.page()
  }, [pathname])

  useEffect(() => {
    const utmParametersDict = generateUTMParametersDict(router)
    if (utmParametersDict)
      setToLocalStorage(
        LOCAL_STORAGE_UTM_PARAMS_KEY,
        JSON.stringify(utmParametersDict),
        () => storeLastTouchUTMParametersInSessionCookie(router)
      )
  }, [])

  useEffect(() => {
    const referrer = document.referrer
    !referrer.includes(window.location.hostname) &&
      setToLocalStorage(SIGNUP_REFERRER_LOCAL_STORAGE_KEY, referrer, () =>
        console.error(
          `Failed to save in local storage. Key: ${SIGNUP_REFERRER_LOCAL_STORAGE_KEY}`
        )
      )
  }, [])

  const pathnameWithoutQuery = pathname.split('?')?.[0] // Remove query parameters from pathname

  const canonicalLink = useMemo(() => {
    const pathnameWithoutQuery = pathname.split('?')?.[0]
    return locale === router.defaultLocale
      ? `https://www.codingal.com${pathnameWithoutQuery}` // Canonical for default locale
      : `https://www.codingal.com${pathnameWithoutQuery}` // Canonical for localized version if needed
  }, [locale, pathnameWithoutQuery])

  const noindex = useMemo(
    () => NOINDEX_PAGE_GLOBS.some((glob) => minimatch(pathname, glob)),
    [pathname, locale]
  )

  const languageAlternates = useMemo(() => {
    // Define your supported locales here based on LOCALE_WISE_CANONICAL_LOCALIZED_PATH_GLOBS
    const supportedLocales = Object.keys(
      LOCALE_WISE_CANONICAL_LOCALIZED_PATH_GLOBS
    )

    // Generate the hreflang URLs for all supported locales except the current locale
    const alternates = supportedLocales
      .filter((lang) => lang !== locale) // Skip the current locale
      .map((lang) => ({
        hrefLang: lang,
        href:
          lang === router.defaultLocale
            ? `https://www.codingal.com${pathnameWithoutQuery}` // Default locale without lang prefix
            : `https://www.codingal.com/${lang}${pathnameWithoutQuery}`, // Other locales with lang prefix
      }))

    // Add the current page version's hreflang (self-referencing)
    alternates.push({
      hrefLang: locale,
      href:
        locale === router.defaultLocale
          ? `https://www.codingal.com${pathnameWithoutQuery}` // Current page for default locale
          : `https://www.codingal.com/${locale}${pathnameWithoutQuery}`, // Current page for non-default locale
    })

    // Optionally include x-default for users without a language preference
    alternates.push({
      hrefLang: 'x-default',
      href: `https://www.codingal.com${pathnameWithoutQuery}`, // Default to base URL
    })

    return alternates
  }, [pathname, locale, router.defaultLocale])

  const shouldDisableHelloBars = useMemo(() => {
    return !GENERAL_HELLO_BAR_EXCLUSION_GLOBS.some((glob) =>
      minimatch(pathname, glob)
    )
  }, [pathname])

  return (
    // Workaround for https://github.com/vercel/next.js/issues/8592
    <>
      <DefaultSeo {...defaultSeo} />

      <Head>
        <link
          rel="icon"
          href={staticFile(
            `/images/logos/logos-${LOGO_THEME}/favicon-32x32.png`
          )}
          type="image/png"
          sizes="32x32"
        />
        <link
          rel="icon"
          href={staticFile(
            `/images/logos/logos-${LOGO_THEME}/favicon-90x90.png`
          )}
          type="image/png"
          sizes="90x90"
        />

        {/* Making non-critical css non-render-blocking.
            More info: https://web.dev/defer-non-critical-css/#optimize
        */}
        {NON_CRITICAL_CSS_HREFS.map((cssHref) => (
          <Fragment key={cssHref}>
            <link
              rel="preload"
              href={cssHref}
              as="style"
              // eslint-disable-next-line
              // @ts-ignore
              onLoad="this.onload=null;this.rel='stylesheet'"
            />
            <noscript>
              <link rel="stylesheet" href={cssHref} />
            </noscript>
          </Fragment>
        ))}
        <meta name="theme-color" content="#e9543e" />
        {
          // Temp fix for classroom recording. This key will be valid until September 4, 2024,
          loggedIn && (
            <meta
              httpEquiv="origin-trial"
              content="ApjlVMPageBpAGC9y5rV1LTReG4OGB0UenV8x2WNWCwW62c0mSeU9NwOe4DG4e/aYR06dnmogn4Ma9it28NDAQEAAAB9eyJvcmlnaW4iOiJodHRwczovL2NvZGluZ2FsLmNvbTo0NDMiLCJmZWF0dXJlIjoiRGlzYWJsZVRoaXJkUGFydHlTdG9yYWdlUGFydGl0aW9uaW5nIiwiZXhwaXJ5IjoxNzI1NDA3OTk5LCJpc1N1YmRvbWFpbiI6dHJ1ZX0="
            />
          )
        }
      </Head>

      {/* Scripts */}
      <>
        <Script
          strategy="lazyOnload"
          type="text/javascript"
          src={staticFile('/offline/offline.js')}
          onLoad={() => {
            {
              /* The script by default requests favicon.ico to check whether the user is online or not.
     We do not have a file with that name so we will be checking for favicon-32x32.png
        */
            }
            if (window?.Offline)
              window.Offline.options = {
                checks: {
                  xhr: {
                    url: staticFile(
                      `/images/logos/logos-${LOGO_THEME}/favicon-32x32.png`
                    ),
                  },
                },
              }
          }}
        />
        {locale === 'translate' && (
          <>
            <Script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `
              var _jipt = []; _jipt.push(['project', 'web-i18n']);
            `,
              }}
            />
            <Script
              type="text/javascript"
              src="//cdn.crowdin.com/jipt/jipt.js"
            />
          </>
        )}

        {isProd && (
          <>
            {/* Google Ads - Global site tag */}
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            ></script>
            <Script
              dangerouslySetInnerHTML={{
                __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        // Google Analytics
                        gtag('config', '${GA_TRACKING_ID}', { 'page_path': window.location.pathname, 'allow_enhanced_conversions': true });

                        // Google Ads - First Account
                        gtag('config', '${AW_CONVERSION_ID}', { 'allow_enhanced_conversions': true });

                        // Google Ads - Second Account (Added Oct 3 2023)
                        gtag('config', 'AW-11346248977');
                        `,
              }}
            />

            {pathname.includes('/trial-class/track-booking/') && (
              <>
                {/* Google Ads Event snippet for MQL conversion page */}
                <Script
                  dangerouslySetInnerHTML={{
                    __html: `gtag('event', 'conversion', {'send_to': 'AW-11346248977/P8ARCKeRr-YYEJGKqKIq'});`,
                  }}
                />
              </>
            )}

            {
              /* Google Ads Event snippet for SQL conversion page*/
              pathname.includes('/trial-class/track-free-class/') && (
                <Script
                  dangerouslySetInnerHTML={{
                    __html: `gtag('event', 'conversion', {
                            'send_to': 'AW-11346248977/eeELCKqRr-YYEJGKqKIq',
                            'transaction_id': ''
                            });`,
                  }}
                />
              )
            }

            {
              /*  Google Ads - Event snippet for MQL - Pageview conversion page  */
              pathname.includes('/trial-class/booking/') && (
                <Script
                  dangerouslySetInnerHTML={{
                    __html: `gtag('event', 'conversion', {'send_to': 'AW-11346248977/fJTzCKSRr-YYEJGKqKIq'});`,
                  }}
                />
              )
            }

            {/* Facebook */}
            <Script
              dangerouslySetInnerHTML={{
                __html: `
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                      `,
              }}
            />
            {/* Sharesale */}
            <script
              src="https://www.dwin1.com/41034.js"
              type="text/javascript"
              defer
            ></script>
            {loggedIn && (
              <Script
                dangerouslySetInnerHTML={{
                  __html: `
                          fbq('init', '443675013217398', ${JSON.stringify(
                            prepareUserDataForFB()
                          )});
                        `,
                }}
              />
            )}
            {!loggedIn && (
              <Script
                dangerouslySetInnerHTML={{
                  __html: `
                          fbq('init', '443675013217398');
                        `,
                }}
              />
            )}

            {/* LeadSquared Tracking Code Start */}
            {!LEADSQUARED_EXCLUSION_GLOBS.some((glob) =>
              minimatch(pathname, glob)
            ) && (
              <>
                <Script
                  src="https://web-in21.mxradon.com/t/Tracker.js"
                  strategy="lazyOnload"
                  onLoad={() => {
                    pidTracker('51399')
                  }}
                />
              </>
            )}
            {/*LeadSquared Tracking Code End*/}

            {/* Rewardfull */}
            <Script
              dangerouslySetInnerHTML={{
                __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');

                (function() {
                  var el = document.createElement('script');
                  el.setAttribute('src', 'https://r.wdfl.co/rw.js');
                  el.setAttribute('data-rewardful', '627bb0');
                  document.body.appendChild(el);
                })();`,
              }}
            />
          </>
        )}
      </>
      <I18nProvider i18n={i18n}>
        <SessionContext.Provider value={{ ...userData }}>
          <FlagsProvider features={getFeaturesForUser(userData)}>
            <AtlasWidget />
            {shouldDisableHelloBars && (
              <>
                <PasswordResetHelloBar />
              </>
            )}
            {showHpeAds && (
              <>
                <HelloBar />
                <Popup
                  displayPopup
                  imageHeight={600}
                  imageWidth={900}
                  imageUrl="/images/hpe-codewars/hpe-2025-popup"
                  targetUrl="/competitions/hpe-codewars-2025/?utm_source=website-popup&utm_medium=hpe-codewars"
                  popupName="HPE CodeWars popup 2025"
                  numberOfTimestoShow={1}
                />
              </>
            )}
            <CompetitionHelloBar />
            {userData.loggedIn && !teacher && (
              <UpdateInfoHelloBar
                allowedPaths={UPDATE_INFO_HELLO_BAR_ENDPOINTS}
              />
            )}
            <Component {...pageProps} err={err} />
            <VerifyLocale />
            <RoutingSpinner />
          </FlagsProvider>
        </SessionContext.Provider>
      </I18nProvider>

      <NextSeo
        noindex={noindex}
        canonical={noindex ? undefined : `${canonicalLink}`}
        languageAlternates={noindex ? undefined : languageAlternates}
      />
    </>
  )
}

App.getInitialProps = async ({ Component, ctx }) => {
  const next = ctx.query?.next
  const isBuilding = process.env.APP_ENV === 'build'
  !isBuilding && (await handleLocaleRedirections(ctx))

  const locale = ctx.locale || ctx.defaultLocal
  const pathname = removeLocaleFromPathname(ctx.asPath, locale)

  let pageProps = {}
  const token = parseCookies(ctx).token
  const userData = await userFetcher('/api/v1/users/me/', token, ctx)
  const queryString = ctx.asPath.split('?')[1]
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  if (userData.loggedIn && hasRoute(pathname, UNAUTHORIZED_ONLY_ENDPOINTS)) {
    if (next) {
      const updatedQueryString = new URLSearchParams(queryString)
      updatedQueryString.delete('next')
      redirectToForGetInitialProps(ctx, `${next}?${updatedQueryString}`)
    } else if (queryString?.length > 0)
      redirectToForGetInitialProps(ctx, `/dashboard/?${queryString}`)
    else redirectToForGetInitialProps(ctx, '/dashboard')
  } else if (
    !userData.loggedIn &&
    hasRoute(pathname, AUTHORIZED_ONLY_ENDPOINTS)
  ) {
    const nextPath = pathname.split('?')[0]
    if (queryString?.length > 0)
      redirectToForGetInitialProps(
        ctx,
        `/login/?next=${nextPath}&${queryString}`
      )
    else redirectToForGetInitialProps(ctx, `/login/?next=${pathname}`)
  }

  const translation = await loadTranslation(locale)

  return { pageProps: { ...pageProps, translation }, userData, pathname }
}
