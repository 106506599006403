import { hasRoute } from '@common/utils'
import { FastlyImage } from '@components/common/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

// Paths that should not show the hello bar
const RESTRICTED_PATHS = [
  '/batches/**',
  '/lp/**',
  '/trial-class/**',
  '/competitions/**/problems/**',
  '/competitions/**/problems',
  '/competitions/**/competition-leaderboard',
  '/competitions/**/submissions',
  '/book-trial-class',
  '/payment/**',
  '/rewind/**',
  '/payment-status*',
  '/register*',
  '/login*',
  '/login/**',
  '/register/**',
  '/404',
]

// paths that should show the hello bar
const ALLOWED_PATHS = [
  '/trial-class/incomplete',
  '/trial-class/complete',
  '/trial-class/join',
]

const HelloBar = () => {
  const { pathname } = useRouter()
  const isHomePage = pathname === '/'

  if (
    hasRoute(pathname, RESTRICTED_PATHS) ||
    !hasRoute(pathname, ALLOWED_PATHS)
  ) {
    return null
  }

  return (
    <>
      <div
        className={`md:top-0 z-10 p-2 md:px-3 lg:text-xl text-center text-white font-600 lg:font-700 ${
          isHomePage ? 'hidden lg:block' : 'block'
        }`}
        style={{
          background:
            'linear-gradient(103.86deg, #1B1D60 0.45%, #1A0A3C 38.59%, #330B44 67.46%, #600D51 100%)',
        }}
      >
        <Link
          href="/competitions/hpe-codewars-2025/?utm_source=website-hellobar&utm_medium=hpe-codewars"
          prefetch={false}
        >
          <a target="_blank" rel="noreferrer" className="hover:underline">
            {' '}
            <FastlyImage
              src="/images/hpe-codewars/hpe-logo.png"
              webpSrc="/images/hpe-codewars/hpe-logo.webp"
              alt="HPE CodeWars"
              className="w-16 h-5 inline-block pr-2"
              width={64}
              height={20}
            />
            HPE CodeWars 2025 is live! Register ow
          </a>
        </Link>
      </div>
    </>
  )
}

export default React.memo(HelloBar)
