import { hasRoute } from '@common/utils'
import { useSessionContext } from '@contexts/session'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useState } from 'react'

declare global {
  interface Window {
    Atlas: any
  }
}

const AtlasWidget = () => {
  const {
    id,
    teacher,
    isPaidUser,
    isCompetitionUser,
    loggedIn,
    email,
    name,
    gender,
    hasLaptop,
    phone,
    student,
    timezone,
    country,
    totalCredits,
    leadSource,
    dob,
    totalCodingCredits,
    totalMathCredits,
    signupSource,
    preferences,
  } = useSessionContext()
  const [userDetails, setUserDetails] = useState({})
  const { pathname } = useRouter()

  const RESTRICTED_PATHS = [
    '/feedback/**',
    '/quizzes/**/arena**',
    '/competitions/**/register/complete/**',
    '/payment-partners*',
    '/payment-status*',
    '/payment/**',
    '/payment/**',
  ]
  const isProd = process.env.NODE_ENV === 'production'

  // Update userDetails whenever session context changes
  useEffect(() => {
    if (!loggedIn) return // No need to set userDetails if not logged in

    const updatedUserDetails = {
      userId: id,
      email: email,
      name: name,
      phoneNumber: phone,
      customFields: {
        country: country,
        totalCredits: totalCredits,
        studentGrade: student?.grade,
        gender: gender,
        isTeacher: teacher?.id ? true : false,
        isPaidUser: isPaidUser,
        isCompetitionUser: isCompetitionUser,
        hasLaptop: hasLaptop,
        timezone: timezone,
        relationshipManagerName: student?.relationshipManager?.name ?? '',
        relationshipManagerPhone: student?.relationshipManager?.phone ?? '',
        relationshipManagerEmail: student?.relationshipManager?.email ?? '',
        classTeacherName: student?.classTeacherDetails?.name ?? '',
        classTeacherEmail: student?.classTeacherDetails?.email ?? '',
        whatsappGroupUrl: student?.whatsappGroupUrl ?? '',
        dateOfBirth: dob ?? '',
        totalCodingCredits: totalCodingCredits ?? '',
        totalMathCredits: totalMathCredits ?? '',
        utmSource: leadSource?.utmSource ?? '',
        utmMedium: leadSource?.utmMedium ?? '',
        signupSource: signupSource ?? '',
        parentName: student?.parentName ?? '',
        whatsappEnabled: preferences?.whatsappEnabled ?? true,
        isTrialClassScheduled: student?.isTrialClassScheduled ?? false,
      },
    }

    setUserDetails(updatedUserDetails)
  }, [
    id,
    teacher,
    isPaidUser,
    isCompetitionUser,
    email,
    name,
    gender,
    hasLaptop,
    phone,
    student,
    timezone,
    country,
    totalCredits,
    loggedIn,
  ])

  useEffect(() => {
    // Hide chat widget on restricted paths
    if (hasRoute(pathname, RESTRICTED_PATHS)) {
      window.Atlas?.call('update', {
        chat: {
          hideBubble: true,
        },
      })
      return
    }
    // Show chat widget on all other paths
    window.Atlas?.call('update', {
      chat: {
        showBubble: true,
        hideBubble: false,
        position: 'bottomRight',
        offset: [20, 20],
      },
    })
    // Show chat widget on left side for in classroom
    if (hasRoute(pathname, ['/batches/**'])) {
      window.Atlas?.call('update', {
        chat: {
          position: 'bottomLeft',
          offset: [20, 20],
        },
      })
    }
    // Show chat widget on left side on competition arena, and disable label
    if (hasRoute(pathname, ['/competitions/**/problems/**'])) {
      window.Atlas?.call('update', {
        chat: {
          position: 'bottomLeft',
          offset: [20, 20],
          launcher: {
            label: '',
          },
        },
      })
    }

    if (!loggedIn || Object.keys(userDetails).length === 0 || !window.Atlas)
      return

    window.Atlas?.call('identify', userDetails)
  }, [userDetails, loggedIn, pathname])

  if (!isProd) return null

  return (
    <Script
      id="atlas-snippet"
      dangerouslySetInnerHTML={{
        __html: `(()=>{"use strict";var t,e={appId:"phg0lup4ll",v:2,q:[],call:function(){this.q.push(arguments)}};window.Atlas=e;var n=document.createElement("script");n.async=!0,n.src="https://app.atlas.so/client-js/atlas.bundle.js";var s=document.getElementsByTagName("script")[0];null===(t=s.parentNode)||void 0===t||t.insertBefore(n,s)})();window.Atlas.call("start");`,
      }}
    />
  )
}

export default AtlasWidget
